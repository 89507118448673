/* Let CRA handle linting for sample app */
import React, { Component } from 'react';
// import Spinner from 'react-spinner';
import classNames from 'classnames';
import EmojiPicker from 'emoji-picker-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccCore, {OpenTokSDK} from 'opentok-accelerator-core';
import AnnotationAccPack from 'opentok-annotation';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import screensharingAccPack from 'opentok-screen-sharing';
import jwt from "jwt-decode";
import moment from 'moment';
import { endCall, updateSerialNumber,getParticipantsInCall, getRecordingStatus, sendTranscriptMsg } from "../../services/meetingService.js";
import { vonageStartStopRecording } from "../../services/vonageService.js";
import { getTemplateData } from "../../services/machineService.js";

import AllApi from "../../Service/services";
import 'opentok-solutions-css';

import logo from './logo.svg';
import config from './config.json';
import './App.css';
import {
  removeAllCursors,
  removeCursor,
  startCursorListener,
  stopCursorListener,
  updateRemoteCursor,
  changeCursorElement,
  updateCursorColor
} from './cursor.js';
import { message } from 'opentok-accelerator-core/dist/logging.js';
import { hours } from 'date-arithmetic';



var annotation = null;
var recording = null;
var screensharingLocal = null;
let callProperties = {
  insertMode: 'append',
  fitMode:"contain",
  resolution:"1920x1080",
  width: "100%",
  height: '100%',
  showControls: false,
  style: {
    buttonDisplayMode: 'off'
  }
};

let callPropertiesBackground = {
  insertMode: 'append',
  fitMode:"cover",
  width: '100%',
  height: '100%',
  showControls: false,
  style: {
    buttonDisplayMode: 'off'
  }
};

const cursorUpdateMessageName = 'cursorPos';

const annotationOptionsOld = {
  screensharing:false,
  canvasContainer:"annotationContainerCustom",
  externalWindow:'.App-annotation-parent-container',
  absoluteParent:".app-annotation-container"
}

const archiving = {
  startURL: 'https://webhook.site/a8e9e93c-0718-4f84-aedf-f3586d6a816d',
  stopURL: 'https://webhook.site/a8e9e93c-0718-4f84-aedf-f3586d6a816d',
}


const annotationOptions = {
  screensharing:false,
  canvasContainer:".app-annotation-container",
  cobrowsingImage: null,
  mobileInitiator: true,
  absoluteParent: '#cameraSubscriberContainer',
  toolbarItems:['pen', 'colors', 'shapes', 'text', 'clear','undo'],
  toolbarShapes: ['rectangle', 'oval'],
  colors:["#FF00F7","#0F0F0F","#FFFFFF","#00FFFF"],
}

const screenshareOptions =  {
  extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
  screenSharingContainer:"screenPublisherContainer",
  annotation: true,
  externalWindow: false,
  dev: true,
  localScreenProperties: {
    insertMode: 'replace',
    width: '100%',
    height: '100%',
    display: "none",
    showControls: false,
    style: {
      buttonDisplayMode: 'on',
      display: "none"
    },
    videoSource: 'window',
    fitMode: 'contain' // Using default
  },
}

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled, screenSharePublishing,pointerStarted, localRecordingEnabled,streamMap, subscribers, isLeading, } = state;

  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraPublishers = meta ? meta.publisher.camera : 0;
  const activeAnnotationButton = (activeCameraSubscribers)?activeCameraSubscribers:true;
  const activeSerialNumberInput = (activeCameraSubscribers || activeCameraPublishers)?(activeCameraSubscribers || activeCameraPublishers):false;
  const activeSerialNumberInputData = (activeCameraSubscribers || activeCameraPublishers)?(activeCameraSubscribers || activeCameraPublishers):false;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  console.log("meta=",meta)
  console.log("camera",activeCameraPublishers)
  return {
    controlClass: classNames('App-control-container', { hidden: !active }),
    videoControls: classNames('video-call-control', { hidden: !active }),
    localAudioClass: classNames('local-audio-class', { hidden: !active, muted: !localAudioEnabled }),
    localVideoClass: classNames('local-video-class', { hidden: !active, muted: !localVideoEnabled }),
    localRecordingClass: classNames('local-recording-class', { enabled: !localRecordingEnabled }),
    localRecordingTimer: classNames('video-recorder', { hidden: !localRecordingEnabled }),
    localScreenShareClass: classNames('local-screenshare-class', { hidden: !active, started: screenSharePublishing }),
    localPointerClassClass: classNames('local-pointer-class', { hidden: !active, started: pointerStarted }),
    localCallClass: classNames('', { hidden: !active }),
    cameraPublisherClass: classNames('video-container', { hidden: !active, small: !!activeCameraSubscribers || screenshareActive, left: screenshareActive }),
    screenPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
    annotationPublisherClass: classNames('video-container', { hidden: !active || !sharingScreen }),
    activeAnnotationButton: classNames('annotation-wrap', { hidden: !active || !activeAnnotationButton }),
    activeSerialNumberInput: classNames('serial-number-wrap', { hidden: !active || !activeSerialNumberInput }),
    waitingForParticipant: classNames('', { hidden: !active || (activeCameraSubscribers>=1) || (isLeading && activeCameraPublishers>=1) }),
    activeSerialNumberInputData: (activeCameraSubscribers || activeCameraPublishers)?(activeCameraSubscribers || activeCameraPublishers):false,
    cameraSubscriberClass: classNames('video-container1', { hidden: !active || !(activeCameraSubscribers || activeCameraPublishers) },
      { 'active-gt2': activeCameraSubscribersGt2 && !screenshareActive,},
      { 'active-odd': activeCameraSubscribersOdd && !screenshareActive },
      { 'cursornone': pointerStarted },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames('video-container', { hidden: !viewingSharedScreen || !active }),
    annotationSubscriberClass: classNames('video-container', { hidden: true || !viewingSharedScreen || !active }),
  };
};

const connectingMask = () =>
  <div className="App-mask">
    {/* <Spinner /> */}
    <div className="message with-spinner">Connecting</div>
  </div>;

const startCallMask = (start, localVideoEnabled, toggleLocalVideo, localAudioEnabled, toggleLocalAudio, isLeading) =>
  <>

  
  <div className="permission-screen-wrap">
            <div className="permission-screen">
                <div className="permission-screen-left">
                  <div className="img-wrap">
                    <a href="#">
                      <img src="assets/images/logo-desktop.png" alt="logo-desktop" className="desktop-view" />
                      <img src="assets/images/logo-mobile.png" alt="logo-mobile" className="mobile-view" />
                    </a>
                  </div>
                </div>
                <div className="permission-screen-right">
                    <div className="permission-screen-info">
                        <div className="title">Choose your audio and video options</div>
                        <div className="control-btn-wrap">
                            <button className={!localAudioEnabled?"audio-btn":"audio-btn active"} onClick={toggleLocalAudio}>
                              {!localAudioEnabled &&<img src="assets/images/audio-mute.svg" className="svg mute" alt="audio-mute" />}
                              {localAudioEnabled && <img src="assets/images/audio-unmute.svg" className="svg unmute" alt="audio-unmute" />}
                            </button>
                            {<button className={(!isLeading || !localVideoEnabled)?"video-btn cursor-disabled":"video-btn active"} onClick={toggleLocalVideo}>
                              {(!isLeading || !localVideoEnabled) && <img src="assets/images/video-mute.svg" className="svg mute" alt="video-mute" />}
                              {(!isLeading || localVideoEnabled) && <img src="assets/images/video-unmute.svg" className="svg unmute" alt="video-unmute" />}
                            </button>}
                        </div>
                        <div className="sub-title">Click on start call button below to start the call</div>
                        {!isLeading && <div className="sub-title">Your camera will be off during this session</div>}
                        <div className="start-call-cta-wrap">
                            <button onClick={() => start()}><span className='phoneIcon'/>Join Call</button>
                        </div>
                        <div className='joining_information'>
                        <div className="title">Tips for your Session:</div>
                        <ul>
                          <li>
                            <span className="sub-title">Headphones: </span>Use them for better audio and to reduce background noise.
                          </li>
                          <li>
                            <span className="sub-title">Chat: </span>Use chat in loud environments for clear communication
                          </li>
                          <li>
                            <span className="sub-title">Camera: </span>The active camera is always from the user who scheduled or sent the call link. Rear-facing is default; switch if needed.
                          </li>
                          <li>
                            <span className="sub-title">Pointer &amp; Tools: </span>Highlight issues using the pointer and annotation tools.
                          </li>
                          <li>
                            <span className="sub-title">Record &amp; Save: </span>Record sessions or take screenshots for reports or future reference
                          </li>
                        </ul>
                        
      
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
  
  ;

let otSDK;
let otCore;
let timer;
let hh,mm,ss;
const api = new AllApi();

class App extends Component {

  
  
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      name: "",
      userData: {},
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      localRecordingEnabled: false,
      isRecordingInitiator: false,
      serialNumber: "",
      isLeading: false,
      annotationStarted: false,
      selectedPointerColor: (localStorage.getItem("selected_color") != null)?localStorage.getItem("selected_color"):'#0F0F0F',
      screenShareStarted: false,
      screenSharePublishing: false,
      recordingAnnotationPublisher: null,
      emojiPickerOpen:false,
      pointerStarted: false,
      messagesCount:0,
      annotationPublisher: {},
      annotationStream: {},
      videoWidth:0,
      activeChat:false,
      videoHeight:0,
      session: null,
      meetingId: "",
      annotation: null,
      streamMap: null,
      localPublisherId: null,
    };
    
    this.startCall = this.startCall.bind(this);
    this.toggleChatWindow = this.toggleChatWindow.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.screenshot = this.screenshot.bind(this);
    this.changeSerialNumber = this.changeSerialNumber.bind(this);
    this.submitSerialNumber = this.submitSerialNumber.bind(this);
    this.printInfo = this.printInfo.bind(this);
    this.stopAnnotation = this.stopAnnotation.bind(this);
    this.endCallButtonClick = this.endCallButtonClick.bind(this);
    this.isJsonString = this.isJsonString.bind(this);
    this.screenShareToggle = this.screenShareToggle.bind(this);
    this.toggleRecording = this.toggleRecording.bind(this);
    this.messageCountChange = this.messageCountChange.bind(this);
    this.pointerStart = this.pointerStart.bind(this);
    this.pointerSelect = this.pointerSelect.bind(this);
    this.changeCursorColor = this.changeCursorColor.bind(this);
    this.changePointerColor = this.changePointerColor.bind(this);
    this.appendEmojiToMessage = this.appendEmojiToMessage.bind(this);
    this.openEmojiBox = this.openEmojiBox.bind(this);
  }

  

  async componentDidMount() {
    const { isRecordingInitiator } = this.state;
    config.sessionId = localStorage.getItem("vonage_session_id");
    config.token = localStorage.getItem("vonage_token");
    const token = localStorage.getItem("token");
    const meeting_id = localStorage.getItem("vonage_meetingId");
    let user = jwt(token);
    let res1 = getParticipantsInCall(meeting_id).then((response) => {
      if(response.status === 200){
        if(response.data.user.id === user.user_id){
          this.setState({isLeading: true});
        }
      }
    });
    
    console.log("user=",user);

    otSDK = new OpenTokSDK(config);


    const otCoreOptions = {
      credentials: {
        apiKey: "06e31ffb-26e6-41a0-bb2b-e57d1f562b14",
        sessionId: localStorage.getItem("vonage_session_id"),
        token: localStorage.getItem("vonage_token"),
      }
    }
    otCore = new AccCore(otCoreOptions);
    console.log("otSDK",otSDK);
    console.log("otSDK",otCore);
    

    const session = otSDK.session;

    otSDK.connect().then(async (res) => {
      console.log("otSDKres",res);
      

      let res2 = getRecordingStatus(meeting_id).then((response) => {

        if(response.status === 200){

            const recordingEnabled = !(Object.keys(response.data.data).length === 0 && response.data.data.constructor === Object);
            
            if(recordingEnabled == true){

              if(response.data.data != null && response.data.data.createdBy.id == user.user_id){
                this.setState({isRecordingInitiator: true});  
              }
  
              this.setState({localRecordingEnabled: recordingEnabled});

              var totalSeconds = 0;
              setTime();
              timer = setInterval(setTime, 1000);

              function setTime() {
                ++totalSeconds;
                console.log("getStatusAPISeconds",totalSeconds)
                hh = pad(Math.floor(totalSeconds / 3600));
                mm = pad(Math.floor(totalSeconds % 3600 / 60));
                ss = pad(Math.floor(totalSeconds % 3600 % 60));
                
                document.getElementById('timer_counter').innerHTML = hh+":"+mm+":"+ss
              }
              
              function pad(val) {
                var valString = val + "";
                if (valString.length < 2) {
                  return "0" + valString;
                } else {
                  return valString;
                }
              }

            }

             
        }
      });



      
    }).then((res2)=>{
      this.setState({ session, connected: true, userData: user, name: user.firstName+' '+user.lastName, meetingId: meeting_id })
    });

    console.log("otSDK",otSDK);

  }
  
  changeCursorColor(color) {

    // Encode the SVG with the new fill color
    const svg = `
        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.23109 21.8329L0.93293 3.11023C0.508124 1.60905 1.97239 0.380386 3.377 1.05941L20.8952 9.52823C22.4824 10.2955 22.3252 12.6047 20.6616 12.9609L13.5842 14.4763C13.0337 14.5942 12.5852 14.9705 12.3735 15.4921L9.6522 22.1989C9.01251 23.7754 6.71112 23.5292 6.23109 21.8329Z" fill="${color}" stroke="white"/>
        </svg>
    `;
    
    // Encode the SVG into a data URL
    const encodedSVG = encodeURIComponent(svg);
    console.log("encodedSVG",encodedSVG);
    // Update the cursor style with the new color
    document.getElementById('cameraSubscriberContainer').style.cursor = `url("data:image/svg+xml,${encodedSVG}") 0 0, auto`;

}

  changePointerColor(color){
    const { selectedPointerColor, userData, pointerStarted } = this.state;
     this.setState({pointerStarted: true})
     this.changeCursorColor(color);
        const callback = (x, y, width, height, xpt, ypt) => {
          const { selectedPointerColor } = this.state;
          
          console.log("Final=",x,y,width, height, xpt, ypt)
          otSDK.signal("pointerLocation",{"x":x,"y":y,"width":width, "height":height, "xpt":xpt, "ypt":ypt, "userName":userData.firstName+' '+userData.lastName, "userId":userData.user_id, color:selectedPointerColor });
          otSDK.signal("pointerColorChange",{"x":x,"y":y,"width":width, "height":height, "xpt":xpt, "ypt":ypt, "userName":userData.firstName+' '+userData.lastName, "userId":userData.user_id, color:selectedPointerColor });
          
        };
        startCursorListener(callback);
    localStorage.setItem("selected_color",color);
    this.setState({selectedPointerColor: color})
    // updateCursorColor(userData.user_id, color)
    

    console.log(selectedPointerColor)
  }

  openEmojiBox(){
    const { emojiPickerOpen } = this.state;
    this.setState({emojiPickerOpen: !emojiPickerOpen})
  }

  appendEmojiToMessage(data){
    console.log("emojidata",data);
    var elem = document.getElementById('msg_input');
    elem.value += data.emoji;
  }

 pointerStart(){

  const { userData,pointerStarted,selectedPointerColor } = this.state;

  if(pointerStarted == false){
    this.setState({pointerStarted: true})
    this.changeCursorColor(selectedPointerColor);
    const callback = (x, y, width, height, xpt, ypt) => {
      const { selectedPointerColor } = this.state;
      otSDK.signal("pointerLocation",{"x":x,"y":y,"width":width, "height":height, "xpt":xpt, "ypt":ypt, "userName":userData.firstName+' '+userData.lastName, "userId":userData.user_id, color:selectedPointerColor });
    };
    startCursorListener(callback);
  }else{
  
    this.setState({pointerStarted: false})
    stopCursorListener();
    removeCursor(userData.user_id);
    document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
    otSDK.signal("removePointerLocation",{"userId":userData.user_id });
  }

 }

  pointerSelect(){
  
    const { userData,pointerStarted,selectedPointerColor } = this.state;

    var pointerMenuOpener = document.querySelector('.pointer-menu-opener');
    var pointerMenu = document.querySelector('.pointer-menu');

        pointerMenuOpener.classList.toggle('active');
        pointerMenu.classList.toggle('active');


    // Close the dropdown if clicked outside
      document.addEventListener('click', function(event) {
        if (!pointerMenuOpener.contains(event.target) && !pointerMenu.contains(event.target)) {
            pointerMenuOpener.classList.remove('active');
            pointerMenu.classList.remove('active');
        }
    });

 
 
    

  }

  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

  startCall() {

        var { session, streamMap, active, annotationPublisher,activeChat,messagesCount,name,localAudioEnabled,localVideoEnabled,screenSharePublishing,isLeading } = this.state;
    
        const getChatTimeFormat = (date) => {
          var d = new Date();
          var hour = d.getHours() == 0 ? 12 : (d.getHours() > 12 ? d.getHours() - 12 : d.getHours());
          var min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
          var ampm = d.getHours() < 12 ? 'AM' : 'PM';
          return hour + ':' + min + ' ' + ampm;
          
        }
      

        const subscribeToStream = stream => {
          var { screenSharePublishing, localRecordingEnabled, recordingAnnotationPublisher, selectedPointerColor } = this.state;
          // console.log(stream);
          // console.log(streamMap[stream.id]);
          if (streamMap && streamMap[stream.id]) { return; }
          const type = stream.videoType;
          console.log("stream",stream)
          console.log("stream",callProperties)
          // console.log("otSDK.state",otSDK.state().subscribers.camera);
          // callProperties.width = stream.videoDimensions.width+"px";
          // callProperties.height = stream.videoDimensions.height+"px";
          console.log("streamData",Object.values(otSDK.state().publishers.camera)[0]);

          let streamData;

          if(this.isJsonString(stream.name)){
            streamData = JSON.parse(stream.name);
            // alert(streamData.platform)
            if(typeof streamData.platform != "undefined" &&  streamData.platform === 'web'){
              document.getElementById('cameraSubscriberContainer').style.aspectRatio = "16/9";
            }else{
              document.getElementById('cameraSubscriberContainer').style.aspectRatio = "9/16";
            }
          }else{
            streamData = stream.name;
          }

          if(streamData.name === "Sharing_Screen"){
            if(screenSharePublishing === true){
              screensharingLocal.end();
              this.setState({screenShareStarted : false, screenSharePublishing: false})
            }
            this.setState({screenShareStarted : true})
          }
          if(typeof streamData.leading != "undefined" &&  streamData.leading == false && streamData.name !== "Sharing_Screen"){
            otSDK.subscribe(stream, `cameraPublisherContainer`, callProperties).then((res) =>
              
              
              
              this.setState(otSDK.state()));  
          }else{
            otSDK.subscribe(stream, `cameraSubscriberContainer`, callProperties).then(({stream}) => 
              
              // otSDK.enableSubscriberAudio(stream.id, true),
            
              console.log("asdasd",stream),
              this.setState(otSDK.state())
            );

            otSDK.subscribe(stream,'main-video-container',{...callProperties, mirror:false, subscribeToAudio:false, fitMode:"cover"})
            
          }
          
          if(streamData.name === "Annotation_Screen"){

            
            // callProperties.width = stream.videoDimensions.width+"px";
            // callProperties.height = stream.videoDimensions.height+"px";
            this.loadCanvas('div');
            console.log("stream",callProperties);
            otSDK.subscribe(stream, `app-annotation-container`,{...callProperties})
            .then(() => this.setState(otSDK.state()));
            annotation = new AnnotationAccPack(otSDK);
            
            annotationOptions.colors.forEach(function(item,i){
              if(item === selectedPointerColor){
                annotationOptions.colors.splice(i, 1);
                annotationOptions.colors.unshift(item);
              }
            });
            
            annotation.start(otSDK.session,annotationOptions);
            annotation.linkCanvas("subscriber",annotationOptions.absoluteParent,annotationOptions);
            
            this.setState({annotationStarted : true})
            annotation.resizeCanvas();    
            annotation.setLineWidthDefault(4);
            console.log("afterNAnnotation",annotation);
          }
        };

        session.streams.forEach(subscribeToStream);
        console.log(otCore)
        let pointerData;
        // Subscribe to new streams and update state when streams are destroyed
        otSDK.on({
          'streamCreated' : ({ stream }) => subscribeToStream(stream),
          "signal:pointerLocation":({ data }) => {

            console.log(data); 
            pointerData = JSON.parse(data)

            if(pointerData.userId !== this.state.userData.user_id){
              if(!(pointerData.xpt<0 || pointerData.ypt<0 || pointerData.xpt>100 || pointerData.ypt>100)){
                updateRemoteCursor(pointerData.userId,pointerData.userName,pointerData.xpt,pointerData.ypt, pointerData.color) 
              }else{
                document.getElementById(`cursor-${pointerData.userID}`).style.display="none";
              }
            }

            
          },
          "signal:pointerColorChange":({ data }) => {

            console.log(data); 
            pointerData = JSON.parse(data)

            if(pointerData.userId !== this.state.userData.user_id){
                changeCursorElement(pointerData.userId,pointerData.color)
            }

            
          },
          "signal:removePointerLocation":({ data }) => {

            console.log(data); 
            pointerData = JSON.parse(data)
            removeCursor(pointerData.userId)
          },
          "signal:recording":(data) => {
            this.setState({localRecordingEnabled : JSON.parse(data.data).isActive})
            if(JSON.parse(data.data).isActive === true){
              var totalSeconds = 0;
              setTime();
              timer = setInterval(setTime, 1000);

              function setTime() {
                ++totalSeconds;
                console.log(totalSeconds)
                hh = pad(Math.floor(totalSeconds / 3600));
                mm = pad(Math.floor(totalSeconds % 3600 / 60));
                ss = pad(Math.floor(totalSeconds % 3600 % 60));
                
                document.getElementById('timer_counter').innerHTML = hh+":"+mm+":"+ss
              }
              
              function pad(val) {
                var valString = val + "";
                if (valString.length < 2) {
                  return "0" + valString;
                } else {
                  return valString;
                }
              }
            }else{
              clearInterval(timer);
              document.getElementById('timer_counter').innerHTML = "00:00:00";
            }
          },
          'streamDestroyed': ({ stream }) => {
            var { localRecordingEnabled, recordingAnnotationPublisher } = this.state;
            let streamData;

            if(this.isJsonString(stream.name)){
              streamData = JSON.parse(stream.name);
              if(typeof streamData.platform != "undefined" &&  streamData.platform === 'web'){
                document.getElementById('cameraSubscriberContainer').style.aspectRatio = "16/9";
              }else{
                document.getElementById('cameraSubscriberContainer').style.aspectRatio = "9/16";
              }
            }else{
              streamData = stream.name;
            }

            if(streamData.name === "Annotation_Screen"){
              annotation.end();
              document.getElementById('app-annotation-container').remove();
              // if(localRecordingEnabled && (recordingAnnotationPublisher != null)){
              //   otSDK.unpublish(this.state.recordingAnnotationPublisher);
              //   this.setState({recordingAnnotationPublisher: null})
              // }
              this.setState({annotationStarted : false})
            }else if(streamData.name === "Sharing_Screen"){
              this.setState({screenShareStarted : false});
            }

            this.setState(otSDK.state())
          },
          'signal:stop-annotation': ({ stream }) => {
            var { localRecordingEnabled, recordingAnnotationPublisher } = this.state;
            // this.setState({annotationStarted : false})
            if(Object.keys(this.state.annotationPublisher).length > 0){
            this.setState({annotationStarted : false})
            otSDK.unpublish(this.state.annotationPublisher);
            this.setState({annotationPublisher: {}});
            annotation.end();
            document.getElementById('app-annotation-container').remove();
            // if(localRecordingEnabled && (recordingAnnotationPublisher != null)){
            //   otSDK.unpublish(this.state.recordingAnnotationPublisher);
            //   this.setState({recordingAnnotationPublisher: null})
            // }
          }
        },
        'signal:start_annotation': ({ stream }) => {
          var { isLeading } = this.state;
          if(isLeading === true){
            this.screenshot();
          }
        }

        
            
        });

        
    

        otSDK.session.on('signal:textChat', function(event) {
          var msg = document.createElement('div');
          var msgHistory = document.getElementById("chatHistory");
          const chatBy = event.from.connectionId === session.connection.connectionId ? 'sender-chat' : 'receiver-chat';

          console.log("event",event);
          console.log("activeChat",document.getElementsByClassName('video-calling-screen-left'));
          if(!document.getElementsByClassName('video-calling-screen-left')[0].classList.contains('active')){
            document.getElementById('badgeIcon').style="display:block"
          }

          if(event.data != null){



            if(false && msgHistory.lastElementChild && (msgHistory.lastElementChild.className === chatBy) && (msgHistory.lastElementChild.getAttribute('user') === event.from.connectionId)){
                
                const name = JSON.parse(event.data).name;
                const message = JSON.parse(event.data).message;

                var lastElement = msgHistory.lastElementChild;
                console.log(lastElement)
                var messageList = lastElement.getElementsByTagName('ul');
                console.log(messageList)

                var msg = document.createElement('li');
              
                msg.innerHTML = `<div class="info-box"><div class="description">`+message+`</div></div>`;

                messageList[0].appendChild(msg);



            }else if(chatBy == 'sender-chat'){

                    const name = JSON.parse(event.data).name;
                    const message = JSON.parse(event.data).message;

                    msg.setAttribute("class",'sender-chat');
                    msg.setAttribute("user",event.from.connectionId);
          

                    msg.innerHTML = `
                          <div class="info">
                            <ul>
                              <li>
                                <div class="info-box">
                                    <div class="author">`+name+`</div>
                                    <div class="description">`+message+`</div>
                                    <div class="time">`+getChatTimeFormat(event.from.creationTime)+`</div>
                                </div>
                            
                              </li>
                            </ul>
                          </div>`;
                          msgHistory.appendChild(msg);
                          {/* <div class="profile-pic">
                            <img src="assets/images/profile-pic.png" alt="profile-pic" />
                          </div> */}
                        
            }else{


              const name = JSON.parse(event.data).name;
              const message = JSON.parse(event.data).message;
              msg.setAttribute("user",event.from.connectionId);
              msg.setAttribute("class",'receiver-chat');
              msg.innerHTML = `<div class="info">
                            <ul>
                              <li>
                                <div class="info-box">
                                    <div class="author">`+name+`</div>
                                    <div class="description">`+message+`</div>
                                    <div class="time">`+getChatTimeFormat(event.from.creationTime)+`</div>
                                </div>
                                
                              </li>
                            </ul>
                          </div>
                          `;
                          
                          msgHistory.appendChild(msg);              
            }
          }
          
          msg.scrollIntoView();
        },this.state);
        

        otSDK.session.on("signal:disconnect-room", async function(event) {
          console.log(event.from.connectionId);
          console.log(otSDK.session.connection.connectionId);
          
          if (event.from.connectionId !== otSDK.session.connection.connectionId) {
            const token = localStorage.getItem("token");
            const obj = { Authorization: "Bearer " + token };
            await api.patchAxiosCalls("PATCH", "/v1/user/isFree", { isFree: true }, obj);
            otSDK.disconnect();
            stopCursorListener();
            document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
            // this.setState({ active: false });

            localStorage.removeItem("vonage_session_id");
            localStorage.removeItem("vonage_token");
            localStorage.removeItem("vonage_meetingId");
            localStorage.removeItem("selected_color");
            window.location.assign('/dashboard/main');
          }
        });



        otSDK.on("signal:stop-annotation", function(event) {
          
          // if(otSDK.state().publishers.custom.cameraPublisherContainer.stream && Object.keys(otSDK.state().publishers.custom.cameraPublisherContainer.stream).length > 0){  
          
          //   otSDK.forceUnpublish(otSDK.state().publishers.custom.cameraPublisherContainer.stream);
          //   // this.setState({annotationPublisher: {}});
          //   annotation.end();
          //   document.getElementById('app-annotation-container').remove();
          //   // this.setState({annotationStarted : false})
          // }
    
      });

        // otSDK.on("signal:recording", function(event) { 
        //   console.log("recordingdata", JSON.parse(event.data));
        //   this.setState({localRecordingEnabled : JSON.parse(event.data).isActive})
        //   // const isActive = JSON.parse(event.data).isActive;
           
        // });

        otSDK.session.on("signal:otAnnotation_clear", function(event) {
          
          
            // if (event.from.connectionId !== otSDK.session.connection.connectionId) {
            //   // Only clear elements drawn by the sender's (from) Id
            //   annotation.clearCanvas(true, event.from.connectionId);
            // }
        });

    // Publish local camera streamconst events

    if(isLeading){
      const publisherObject = {};
      publisherObject.name = "Publisher View";
      publisherObject.platform = "web";
      publisherObject.leading = true;
      callProperties.name = JSON.stringify(publisherObject);
      // document.getElementById('cameraSubscriberContainer').style.aspectRatio = "16/9";
      

      
      const pubb = otSDK.publish('cameraSubscriberContainer', {...callProperties, mirror:false, publishAudio:localAudioEnabled, publishVideo:localVideoEnabled})
      .then((publisher) => {
        console.log("publisher",publisher);
        otSDK.subscribe(publisher.stream,'main-video-container',{...callProperties, mirror:false,publishVideo:localVideoEnabled, fitMode:"cover",})
        this.setState(Object.assign({}, otSDK.state(), { localPublisherId: publisher.id }));
        console.log("publisher",otSDK.state());
      }).catch(error => console.log(error));


      


   
    }else{
      const publisherObject = {};
      publisherObject.name = "Publisher View";
      publisherObject.platform = "web";
      publisherObject.leading = false;
      callProperties.name = JSON.stringify(publisherObject);
      // document.getElementById('cameraSubscriberContainer').style.aspectRatio = "16/9";
      otSDK.publish('cameraPublisherContainer', {...callProperties, mirror:false, publishAudio:localAudioEnabled, publishVideo:false})
      .then((publisher) => {
        console.log("publisher",publisher);
        this.setState(Object.assign({}, otSDK.state(), { localPublisherId: publisher.id }));
        console.log("publisher",otSDK.state());
      }).catch(error => console.log(error));
    }

    
    this.setState({ active: true });

  }




  toggleLocalAudio() {
    const { localPublisherId, publishers, localAudioEnabled } = this.state;
    const enabled = !localAudioEnabled;
    otSDK.enablePublisherAudio(enabled);
    this.setState({ localAudioEnabled: enabled });
  }

  messageCountChange() {
    const {messagesCount} = this.state;
    this.setState({messagesCount: messagesCount+1});
  }

  toggleLocalVideo() {
    const { localPublisherId, publishers, localVideoEnabled, isLeading } = this.state;
    if(isLeading){
      const enabled = !localVideoEnabled;
      otSDK.enablePublisherVideo(enabled);
      this.setState({ localVideoEnabled: enabled});
    }
    
  }

  createCanvasStream = (img) => {
    const {annotationStarted } = this.state;
      var canvas = document.getElementById('app-annotation-container');
      var ctx = canvas.getContext("2d");
   
      ctx.drawImage(img, 0, 0);
      
      // var img1 = document.getElementById("annotation_image");
      (function loop() {
       
        setTimeout(loop, 1000 / 10)
        if(document.getElementById('app-annotation-container') == undefined){
          return;
        }
        ctx.drawImage(img, 0, 0);
        // ctx.drawImage(img, cx, cy, cw, ch,  iwidth, iheight, w, h);
          
          
      })();

      return canvas.captureStream(10).getVideoTracks()[0];
    
  }


  stopAnnotation() {
    
    const { annotationStarted, annotationPublisher,localRecordingEnabled,recordingAnnotationPublisher } = this.state;
    console.log("annotationPublisher",annotationPublisher);
    // if(localRecordingEnabled && (recordingAnnotationPublisher != null)){
    //   otSDK.unpublish(this.state.recordingAnnotationPublisher);
    //   this.setState({recordingAnnotationPublisher: null})
    // }
    
    if(Object.keys(annotationPublisher).length > 0){
      this.setState({annotationStarted : false})
      otSDK.unpublish(annotationPublisher);
      this.setState({annotationPublisher: {}});
      annotation.end();
      document.getElementById('app-annotation-container').remove();

    }else{
      otSDK.signal("stop-annotation",null);
      
    }

  }

  loadCanvas(type) {
      if(document.getElementById('app-annotation-container')){
        document.getElementById('app-annotation-container').remove()
      }
  
      var canvas = document.createElement(type);
      const div = document.getElementById('cameraSubscriberContainer'); 
      canvas.id     = "app-annotation-container";
      // canvas.width = 442;
      // canvas.height = 786;
      // canvas.style.zIndex   = 99;
      canvas.style.position = "absolute";
      div.appendChild(canvas)
  }

  screenshot() {
    
    const { isLeading,localRecordingEnabled, recordingAnnotationPublisher,userData } = this.state;
    this.setState({pointerStarted: false})
    stopCursorListener();
    removeCursor(userData.user_id);
    otSDK.signal("removePointerLocation",{"userId":userData.user_id });
    document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
    if(isLeading === false){
      console.log("anotation_signal_sent")
      otSDK.signal("start_annotation",null);
    }else{

      this.loadCanvas('canvas');
      this.setState({annotationStarted : true})
      var w,h,offsetX,offsetY;
  
      var imgData = Object.values(otSDK.state().publishers.camera)[0].getImgData();
      const height = Object.values(otSDK.state().publishers.camera)[0].videoHeight();
      const width = Object.values(otSDK.state().publishers.camera)[0].videoWidth();
    
      var img = document.createElement("img");
  
      img.setAttribute("src", "data:image/png;base64," + imgData);
      img.setAttribute("id", "annotation_image");
      document.getElementById("app-annotation-container").setAttribute('width',width);
      document.getElementById("app-annotation-container").setAttribute("height",height);
      document.getElementById("app-annotation-container").appendChild(img);
  
      const publisherObject = {};
      publisherObject.name = "Annotation_Screen";
      publisherObject.platform = "web";
      publisherObject.leading = isLeading;
        

      otSDK.publish('cameraPublisherContainer', {
        // c1 is the text canvas. Substitute c2 if you want to use the overlay canvas.
            videoSource: this.createCanvasStream(img),
            // videoSource: this.createCanvasStream(img),
            width: img.width,
            publishAudio:false,
            height: img.height,
            name: JSON.stringify(publisherObject),
            showControls: false,
            // videoSource: "window"
          })
      .then((publisher) => {
        console.log("publisher",publisher);
        this.setState({annotationPublisher: publisher});
        this.setState(Object.assign({}, otSDK.state(), { localPublisherId: publisher.id }));
        console.log("publisher",otSDK.state());
      }).catch(error => console.log(error));
  
      annotation = new AnnotationAccPack(otSDK);
      annotation.start(otSDK.session,annotationOptions);
      annotation.linkCanvas("subscriber",annotationOptions.absoluteParent,annotationOptions);
      annotation.setLineWidthDefault(4);
      annotation.resizeCanvas();    

      if(localRecordingEnabled && (recordingAnnotationPublisher==null)){

        const publisherObject = {};
        publisherObject.name = "record_annotations_Screen";
        publisherObject.platform = "web";
        publisherObject.leading = false;
  
        otSDK.publish('cameraPublisherContainer', {
          // c1 is the text canvas. Substitute c2 if you want to use the overlay canvas.
              videoSource: this.createCanvasStream1(),
              // videoSource: this.createCanvasStream(img),
              // width: img.width,
              publishAudio:false,
              // height: img.height,
              name: JSON.stringify(publisherObject),
              showControls: false,
              // videoSource: "window"
            })
        .then(async (publisher) =>  {
          this.setState({recordingAnnotationPublisher: publisher});
          this.setState(Object.assign({}, otSDK.state(), { localPublisherId: publisher.id }));
          console.log("publisher",otSDK.state());
        }).catch(error => console.log(error));
      }
      console.log(annotation);
    }
    
  }


  erase() {
    console.log(this.state);
  }
  

  printInfo() {
    console.log(this.state.subscribers);
    var imgDataHeight = Object.values(this.state.subscribers.camera)[0].videoHeight();
    var imgDataWidth = Object.values(this.state.subscribers.camera)[0].videoWidth();
    console.log("asdasdasd", imgDataHeight, imgDataWidth);
  }

  toggleChatWindow() {
    this.setState({activeChat: !this.state.activeChat})
    document.getElementById('badgeIcon').style="display:none"
    
  }

  async sendMessage() {
    const {name,meetingId, userData } = this.state;
    console.log(this.state);
    var data = document.getElementById('msg_input');
    console.log(data.value)
    console.log(data.value.length)
    if(data.value.length > 0){
      otSDK.signal("textChat",{"name":name,"message":data.value});
      const timestampData = {
        "timestamp": moment().format("DD MMM YYYY HH:mm:ss"),
        "message": data.value
      }
      document.getElementById('msg_input').value = '';
      sendTranscriptMsg(meetingId,timestampData);

    }
    
  }

  enterPresses(e){
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.sendMessage();
    }
  }



  changeSerialNumber(event) {
    this.setState({serialNumber: event.target.value})
  }

  async submitSerialNumber(){

    const data =  {
      meetingId: this.state.meetingId,
      serialNumber: this.state.serialNumber,
    };

    let res = await updateSerialNumber(data).then((response) => {
      console.log("response",response.status);
      if(response.status === 200){
        document.getElementById('serial-number-update-message-id').classList.remove("hidden");
      }
    });
  }


  createCanvasStream1() {

    var can = document.getElementById('app-annotation-container');
    var ctx = can.getContext('2d');

    var can2 = document.getElementById('opentok_canvas');
    var ctx2 = can2.getContext('2d');

    var canvas = document.createElement('canvas');
    canvas.id     = "app-annotation-container2";

    console.log(can2.width)
    console.log(can2.height)

    canvas.width = can2.width;
    canvas.height = can2.height;
    var ctx3 = canvas.getContext('2d');

    ctx3.drawImage(can, 0, 0, can.width, can.height, 0, 0,canvas.width,canvas.height);
    ctx3.drawImage(can2, 0, 0);
    

    // var img1 = document.getElementById("annotation_image");
    (function loop() {
            
        setTimeout(loop, 1000 / 10)
        if(document.getElementById('app-annotation-container') == undefined){
          return;
        }
        ctx3.drawImage(can, 0, 0, can.width, can.height, 0, 0,canvas.width,canvas.height);
        ctx3.drawImage(can2, 0, 0);
        // ctx.drawImage(img, cx, cy, cw, ch,  iwidth, iheight, w, h);
          
          
      })();

      return canvas.captureStream(10).getVideoTracks()[0];

  
  }

  async toggleRecording() {

    // annotation.resizeCanvas();    
  
    var { localRecordingEnabled,session,meetingId,isRecordingInitiator,recordingAnnotationPublisher,annotationStarted } = this.state;
    


    if(!localRecordingEnabled && annotationStarted){

      const publisherObject = {};
        publisherObject.name = "record_annotations_Screen";
        publisherObject.platform = "web";
        publisherObject.leading = false;
  
        otSDK.publish('cameraPublisherContainer', {
          // c1 is the text canvas. Substitute c2 if you want to use the overlay canvas.
              videoSource: this.createCanvasStream1(),
              // videoSource: this.createCanvasStream(img),
              // width: img.width,
              publishAudio:false,
              // height: img.height,
              name: JSON.stringify(publisherObject),
              showControls: false,
              // videoSource: "window"
            })
        .then(async (publisher) =>  {
          console.log("publisherrecording",publisher);
          this.setState({recordingAnnotationPublisher: publisher});
          this.setState(Object.assign({}, otSDK.state(), { localPublisherId: publisher.id }));

          const data = {
            "action": !localRecordingEnabled ? "start" :  "stop",
            "sessionID": session.sessionId,
            "meetingID": meetingId,
            "removeStreamID": publisher.stream.id
          }  
          let res = await vonageStartStopRecording(data).then((response) => {
            console.log("recording=",response);
            if(response.status === 200){
              otSDK.signal("recording",{isActive: !localRecordingEnabled});
              this.setState({localRecordingEnabled: !localRecordingEnabled, isRecordingInitiator: !isRecordingInitiator});  
            }else{
      
            }
          });

          console.log("publisher",otSDK.state());
        }).catch(error => console.log(error));

 
  }else{

    // if(localRecordingEnabled && (recordingAnnotationPublisher != null)){
    //   otSDK.unpublish(this.state.recordingAnnotationPublisher);
    //   this.setState({recordingAnnotationPublisher: null})
    // }
    const data = {
      "action": !localRecordingEnabled ? "start" :  "stop",
      "sessionID": session.sessionId,
      "meetingID": meetingId
    }  
    let res = await vonageStartStopRecording(data).then((response) => {
      console.log("recording=",response);
      if(response.status === 200){
        otSDK.signal("recording",{isActive: !localRecordingEnabled});
        this.setState({localRecordingEnabled: !localRecordingEnabled, isRecordingInitiator: !isRecordingInitiator});  
      }else{

      }
    });
  }
  }
  screenShareToggle() {
    var { session,screenShareStarted,screenSharePublishing,isLeading,userData } = this.state;
    this.setState({pointerStarted: false})
    stopCursorListener();
    removeCursor(userData.user_id);
    otSDK.signal("removePointerLocation",{"userId":userData.user_id });
    document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
    if(screenSharePublishing === false){
      
      const publisherObject = {};
      publisherObject.name = "Sharing_Screen";
      publisherObject.platform = "web";
      publisherObject.leading = isLeading;
      screensharingLocal = new screensharingAccPack({...screenshareOptions, session:session, accPack:otCore.getAccPack()});
      screensharingLocal.start({publishAudio:false,
        name: JSON.stringify(publisherObject)})
      this.setState({screenShareStarted : true, screenSharePublishing: true})
    }else{
      screensharingLocal.end();
      this.setState({screenShareStarted : false, screenSharePublishing: false})
    }
    // console.log(screensharingLocal.publisher.on("startScreenSharing",(data)=>{alert()}));
    console.log(screensharingLocal);
    
    
  }

  async endCallButtonClick(){
   
    const {meetingId} = this.state;
    
    otSDK.signal("disconnect-room",null);
   
    if (meetingId) {
      const data = {
        meeting_id: meetingId,
        meeting_status: (Object.keys(otSDK.state().streamMap).length<=1)?2:1,
      };
      const token = localStorage.getItem("token");
      const obj = { Authorization: "Bearer " + token };
      await api.patchAxiosCalls("PATCH", "/v1/user/isFree", { isFree: true }, obj);
      
      console.log(token);

  
      try {
        console.log(data)
        let res = await endCall(data);

        otSDK.disconnect();
        stopCursorListener();
        document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
        localStorage.removeItem("vonage_session_id");
        localStorage.removeItem("vonage_token");
        localStorage.removeItem("vonage_meetingId");
        localStorage.removeItem("selected_color");
        
        // this.setState({ active: false });

        window.location.assign('/dashboard/main');
        
      } catch (err) {
        otSDK.disconnect();
        stopCursorListener();
        document.getElementById('cameraSubscriberContainer').style.cursor = `default`;
        // this.setState({ active: false });
        localStorage.removeItem("vonage_session_id");
        localStorage.removeItem("vonage_token");
        localStorage.removeItem("vonage_meetingId");
        localStorage.removeItem("selected_color");
        window.location.assign('/dashboard/main');
      }
        
    }
  };



  render() {
    const { connected, active,activeChat,annotationStarted,localAudioEnabled,localVideoEnabled,screenShareStarted,screenSharePublishing,messagesCount,pointerStarted,emojiPickerOpen,isLeading, localRecordingEnabled,isRecordingInitiator } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localScreenShareClass,
      localCallClass,
      controlClass,
      videoControls,
      cameraPublisherClass,
      screenPublisherClass,
      annotationPublisherClass,
      localRecordingClass,
      cameraSubscriberClass,
      screenSubscriberClass,
      waitingForParticipant,
      localPointerClassClass,
      localRecordingTimer,
      activeAnnotationButton,
      activeSerialNumberInput,
      activeSerialNumberInputData,
      annotationSubscriberClass
    } = containerClasses(this.state);



    return (
      <>
      {/* { !connected && connectingMask() } */}
      { (!connected || (connected && !active )) && startCallMask(this.startCall, localVideoEnabled, this.toggleLocalVideo, localAudioEnabled, this.toggleLocalAudio, isLeading)}
      <div id='content' className={(connected && active)?"video-call-app":"video-call-app hidden"}>
      <div className="video-calling-screen-wrap">
          <div className={activeChat?"video-calling-screen-left active":"video-calling-screen-left"}>
              <div className="video-call-screen">
                  <div className="video-call-frame">
                  <div id="main-video-container" className="App-video-container">
                    <div id="cameraPublisherContainer" style={{display: 'none'}}  className={cameraPublisherClass} />
                    <div id="screenPublisherContainer" style={{display: 'none'}}  className={screenPublisherClass} />
                    <div id="waitingForParticipantContainer" className={waitingForParticipant} >Waiting for participants to join!</div>
                    <div id="cameraSubscriberContainer" className={cameraSubscriberClass} style={{aspectRatio: "16/9"}}/>
                    <div id="screenSubscriberContainer" className={screenSubscriberClass} />

                  </div>

                      <div className={localRecordingTimer} id="recording_badge">
                          <div className="pulse-effect">
                            <div className="pulse-dot"></div>
                          </div>
                          <div className="timer" id="timer_counter">00:00:00</div>
                      </div>

                      {(!screenShareStarted && activeSerialNumberInputData) && <div className={activeAnnotationButton} >
                          {!annotationStarted ? <div className="annotation-cta" id='startAnnotationCustom' onClick={this.screenshot}>
                            <button className="cta-hover"><img src="assets/images/pen-icon.svg" alt="pen-icon" /> Start Annotation</button>
                          </div> : <div className="annotation-cta" id='startAnnotationCustom' onClick={this.stopAnnotation}>
                            <button className="cta-hover"><img src="assets/images/pen-icon.svg" alt="pen-icon" /> Stop Annotation</button>
                          </div>}
                          {/* <div className="eraser-cta">
                            <button className="cta-hover" id='OT_undo'><img src="assets/images/eraser-icon.svg" alt="eraser-icon" /></button>
                          </div> */}
                      </div>}

                      <div className={activeSerialNumberInput} >
                        <div className="input-group">
                          <input type="text" placeholder="Update Serial Number" onChange={this.changeSerialNumber} aria-label="Update Serial Number" aria-describedby="button-serial-number" />
                          <button className="send-btn" type="button" onClick={this.submitSerialNumber} id="button-serial-number"><img src="assets/images/send-icon.svg" alt="send-icon" /></button>
                          
                        </div>
                        <p id="serial-number-update-message-id" className="serial-number-update-message hidden">Serial Number Updated Successfully</p>
                      </div>

                      <div className="full-screen-wrap hidden">
                        <div className="fullscreen-cta">
                          <button className="cta-hover"><img src="assets/images/full-screen-icon.svg" alt="full-screen-icon" /></button>
                        </div> 
                      </div>

                      <div className="screen-capture-wrap hidden">
                        <div className="screen-capture-cta">
                          <button className="cta-hover"><img src="assets/images/screen-capture-icon.svg" alt="screen-capture-icon" /></button>
                        </div> 
                      </div>
                      <div className="refresh-wrap">
                        <div className="refresh-cta">
                          <button className="cta-hover"><img src="assets/images/refresh-icon.svg" alt="refresh-icon" /></button>
                        </div>
                      </div>
                  </div>
              </div>
              <div className={videoControls}>
                  <div className="control-listing">
                    <ul>
                    <div id="controls" className={controlClass}>
                      {/* <div className={localCallClass} onClick={this.endCall} />
                      <div className={localVideoClass} onClick={this.screenshot} />
                      <div onClick={this.printInfo} >Print Info</div>
                      <div className={localCallClass} id='startAnnotationCustom' onClick={this.startCustomAnnotation} /> */}
                      
                   
                      <li>
                        <div className="control-cta">
                          <button className={localAudioClass} onClick={this.toggleLocalAudio}> {localAudioEnabled?<img src="assets/images/Mic On.svg" alt="microphone-icon" />:<img src="assets/images/Mic Off.svg" alt="microphone-icon" />}</button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button className={localVideoClass} disabled={!isLeading} onClick={this.toggleLocalVideo}> {isLeading && localVideoEnabled?<img src="assets/images/Video On.svg" alt="microphone-icon" />:<img src="assets/images/Video Off.svg" alt="microphone-icon" />}</button>
                        </div>
                      </li>
                      {/* <li>
                        <div className="control-cta">
                          <button ><img src="assets/images/camera-rotate-icon.svg" alt="camera-rotate-icon" /></button>
                        </div>
                      </li> */}
                      <li>
                        <div className="control-cta end-call">
                          <button className={localRecordingClass}  disabled={(localRecordingEnabled && !isRecordingInitiator)} onClick={() => this.toggleRecording()}> {!localRecordingEnabled?<img src="assets/images/Start Recording.png" alt="" />:<img src="assets/images/Stop Recording.png" alt="" />} </button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          {!screenSharePublishing?<button className={localScreenShareClass} onClick={() => this.screenShareToggle()}><img src="assets/images/screen-share-mobile-white.svg"  alt="" /></button>:<button className={localScreenShareClass}  onClick={() => this.screenShareToggle()}><img  src="assets/images/screen-share-mobile.svg"  alt="" /></button>}
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button onClick={this.toggleChatWindow} className="chat-cta"><img src="assets/images/message-icon.svg" alt="message-icon" />
                          {console.log("messagesCount",messagesCount)}
                          {<span className="badge" id="badgeIcon" style={{display: "none"}}>&nbsp;</span>}
                          </button>
                        </div>
                      </li>
                      {/* <li>
                        <div className="control-cta">
                          <button  onAuxClick={this.changePointerColor}  className={localPointerClassClass}>  {pointerStarted?<img src="assets/images/pointer-blue.svg" alt="pointer-icon" />:<img src="assets/images/pointer-white.svg" alt="pointer-icon" />} </button>
                        </div>
                      </li> */}
                      <li>
                      <div className="pointer-wrap">
                      <ul>
                        <li>
                        <a href="#" className="pointer-menu-opener" style={{"background": pointerStarted ? '#e0e8ff' : '#197dce'}}>{pointerStarted?<><img onClick={this.pointerStart} src="assets/images/pointer-blue.svg" alt="" /><img className="menu-arrow svg" onClick={this.pointerSelect} src="assets/images/chevron-down-blue.svg"  alt="" /></>:<><img onClick={this.pointerStart} src="assets/images/pointer-white.svg" alt="" /><img className="menu-arrow svg" onClick={this.pointerSelect} src="assets/images/chevron-down.svg"  alt="" /></>}</a>
                        <ul className="pointer-menu" style={{display: "block"}}>
                          <li>
                            <a href="#" onClick={()=>this.changePointerColor('#0F0F0F')}><img src="assets/images/cursor/0F0F0F.svg" alt=""/>Default</a>
                          </li>
                          <li>
                            <a href="#" onClick={()=>this.changePointerColor('#FFFFFF')}><img src="assets/images/cursor/FFFFFF.svg" alt=""/>White</a>
                          </li>
                          <li>
                            <a href="#" onClick={()=>this.changePointerColor("#FF00F7")}><img src="assets/images/cursor/FF00F7.svg" alt=""/>Pink</a>
                          </li>
                          <li>
                            <a href="#" onClick={()=>this.changePointerColor('#00FFFF')}><img src="assets/images/cursor/00FFFF.svg" alt=""/>Turquoise</a>
                          </li>
                          {/* <li>
                            <a href="#"><img src="assets/images/hand-mobile.svg" className="svg" alt="" />Hand<img src="assets/images/chevron-down-mobile.svg" className="menu-arrow svg" alt="" /></a>
                            <div className="pointer-sub-menu">
                              <ul>
                                <li><a href="#"><img src="assets/images/pointer-white.svg" alt=""/>small</a></li>
                              </ul>
                            </div>
                          </li> */}
                        </ul>
                      </li>
                      </ul>
                      </div>
                      </li>
                      {/* <li>
                        <div className="control-cta">
                          <button><img src="assets/images/three-dots.svg" alt="" /></button>
                        </div>
                      </li> */}
                      </div>
                    </ul>
                  </div>
                  <div className="call-end-control-listing">
                    <ul>
                    <div id="controls" className={controlClass}>
                      <li>
                        <div className="control-cta end-call-cta">
                          <button onClick={() => this.endCallButtonClick()}>
                            {/* <img src="assets/images/three-dots.svg" alt="" />  */}
                            End Call</button>
                        </div>
                      </li>
                      </div>
                    </ul>
                  </div>
                  <div className="mobile-control">
                    <ul>
                      <li>
                        <div className="control-cta">
                          <button className={localVideoClass} disabled={!isLeading} onClick={this.toggleLocalVideo}>{(isLeading && localVideoEnabled)?<img src="assets/images/video-icon-mobile-on.svg" alt="video-icon-mobile" />:<img src="assets/images/video-icon-mobile-off.svg" alt="video-icon-mobile" />}</button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                          <button  className={localAudioClass} onClick={this.toggleLocalAudio}>{localAudioEnabled?<img src="assets/images/speaker-icon-on.svg" alt="speaker-icon" />:<img src="assets/images/speaker-icon-off.svg" alt="speaker-icon" />}</button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta cut-call">
                        <button className={localRecordingClass} disabled={(localRecordingEnabled && !isRecordingInitiator)} onClick={() => this.toggleRecording()}> {localRecordingEnabled?<img src="assets/images/Start Recording.png" alt="" />:<img src="assets/images/Stop Recording.png" alt="" />} </button>
                        </div>
                      </li>
                      <li>
                        <div className="control-cta">
                        <button className={localRecordingClass} onClick={() => this.screenShareToggle()}> {screenSharePublishing?<img src="assets/images/screen-share-mobile-off.svg" alt="" />:<img src="assets/images/screen-share-mobile.svg" alt="" />} </button>
                        </div>
                      </li>

                        


                      <li>
                        <div className="control-cta">
                          <button onClick={this.toggleChatWindow}  className="chat-cta-mobile"><img src="assets/images/chat-icon.svg" alt="chat-icon" /></button>
                        </div>
                      </li>
                      
                      <li>
                        <div className="control-cta">
                          
                        {((!screenShareStarted && activeSerialNumberInputData) && !annotationStarted) ? <button onClick={this.screenshot}><img src="assets/images/pen-icon-mobile.svg" alt="pen-icon-mobile" /></button> : <button onClick={this.stopAnnotation}><img src="assets/images/pen-icon-mobile.svg" alt="pen-icon-mobile" /></button> }
                          
                        
                    
                        </div>
                      </li>
                      <li>
                        <div className="control-cta cut-call">
                          <button onClick={() => this.endCallButtonClick()}><img src="assets/images/Phone-mobile.svg" alt="phone-icon" /></button>
                        </div>
                      </li>

            

                      {/* <li>
                        <div className="control-cta">
                          <button><img src="assets/images/camera-flip.svg" alt="camera-flip" /></button>
                        </div>
                      </li> */}
                      {/* <li>
                        <div className="control-cta">
                          <button onClick={this.pointerStart} onAuxClick={this.changePointerColor}   className="chat-cta-mobile">{pointerStarted?<img src="assets/images/pointer-white.svg" alt="pointer-icon" />:<img src="assets/images/pointer-blue.svg" alt="pointer-icon" />}</button>
                        </div>
                      </li> */}
                      <li>
                        <div className="control-cta">
                          <button className="three-dots"><img src="assets/images/three-dots.svg" alt="three-dots" /></button>
                        </div>
                      </li>
                    </ul>
                  </div>
              </div>
          </div>
          {/* {activeChat?"video-calling-screen-right":"video-calling-screen-right active"} */}
          <div className={activeChat?"video-calling-screen-right active":"video-calling-screen-right"}>
              <div className="video-chat-wrap">
                  <div className="chat-heading-wrap">
                    <div className="chat-heading">Chat</div>
                    <div className="close-cta">
                      <button className="close-chat" onClick={this.toggleChatWindow}>X</button>
                    </div>
                  </div>
                  <div id="chatHistory" className="chat-info">

                  </div>
              </div>
              <div className="chat-input-wrap">
              <FontAwesomeIcon className='smileIcon' size='2x'  icon={faSmile} onClick={this.openEmojiBox}/> 
                <div className="input-group">
                  {/* <button className="pin-btn"><img src="assets/images/pin-icon.svg" alt="pin-icon" /></button> */}
                  <input type="text" placeholder="Type Something..." id="msg_input" onKeyUp={(e) => this.enterPresses(e)} aria-label="Type Something..." aria-describedby="button-send-chat" /><EmojiPicker  className="emojipicker" open={emojiPickerOpen} searchDisabled={true} autoFocusSearch={false} reactionsDefaultOpen={false} previewConfig={{showPreview: false}} lazyLoadEmojis={true} onEmojiClick={this.appendEmojiToMessage}/>
                    <button className="send-btn" type="button" id="button-send-chat"><img src="assets/images/send-icon.svg" alt="send-icon" onClick={() => this.sendMessage()}/></button>
                </div>
              </div>  
          </div>
          <div id="App-screenshot-container" className="App-screenshot-container" />
          {/* <div className="App-annotation-parent-container">
            <div id="annotationPublisherContainer" className={annotationPublisherClass} />
            <div id="annotationSubscriberContainer" className={annotationSubscriberClass} />
              <canvas id="app-annotation-container" width="640" height="480" />
            </div> */}
          <div className="other-mobile-info">
            <div className="mobile-info-close-cta">
              <button className="mobile-info-close">X</button>
            </div>
              <ul>
                <li>
                  <button><img src="assets/images/stop-record-icon.svg" alt="" />Stop Recording</button>
                </li>
                <li>
                  <button><img src="assets/images/screen-share-mobile.svg" alt="screen-share" />Screen Share</button>
                </li>
                <li>
                  <button className="serial-no"><img src="assets/images/barcode.svg" alt="barcode" />Serial Number</button>
                </li>
                <li>
                  <button><img src="assets/images/capture-pic.svg" alt="capture-pic" />Capture Screenshot</button>
                </li>
              
                {/* <div className="pointer-wrap">
                  <ul className="pointer-menu">
                    <li>
                      <a href="#"><img src="assets/images/pointer-white.svg" className="svg" alt=""/>Pointer<img src="assets/images/chevron-down-mobile.svg" className="menu-arrow svg" alt=""/></a>
                      <div className="pointer-sub-menu">
                        <ul>
                          <li><a href="#"><img src="assets/images/pointer-white.svg" alt=""/>small</a></li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="#"><img src="assets/images/hand-mobile.svg" className="svg" alt="">Hand<img src="assets/images/chevron-down-mobile.svg" className="menu-arrow svg" alt=""></a>
                      <div className="pointer-sub-menu">
                        <ul>
                          <li><a href="#"><img src="assets/images/pointer-white.svg" alt="asd" />small</a></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div> */}
              
                {/* <div className="serial-no-mobile">
                    <div className="serial-no-box">
                      <div className="top">
                        <div className="title">Update Serial Number</div>
                        <div className="serial-no-close-cta">
                          <button className="serial-no-close">X</button>
                        </div>
                      </div>
                      <div className="serial-number-wrap">
                        <div className="input-group">
                          <input type="text" placeholder="Enter here..." aria-label="Enter here..." aria-describedby="button-serial-number" />
                          <button className="send-btn" type="button" id="button-serial-number"><img src="assets/images/send-icon.svg" alt="send-icon" /></button>
                        </div>
                      </div>
                    </div>
                </div> */}
              </ul>
          </div>
      </div>
    </div>
      
      </>
      
    );
  }
}

export default App;
